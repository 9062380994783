import React from 'react';

const Resume =()=>{
    return(
        <>
           {
             
           }
        </>
    );
}

export default Resume;